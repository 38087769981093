import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { UserRegisterModel } from "../../core/domain/user-register-model";
import { AlojamientoFiltersModel, AlojamientosResponseModel } from "../../core/domain/mapa/alojamiento-filters-model";
import { UserReservationDetails } from "../../core/domain/mapa/reservation-model";
import { Estados, UserReservationModel } from "../../core/domain/mapa/user-reservation-model";

@Injectable({
    providedIn: 'root'
})
export class ReservationRespository{

    constructor(private _http: HttpClient) {
       
    }

    getUserOldReservations(filters?:any | undefined): Observable<Estados[]> {
        let queryParams = '';
        if (filters) {
            const filterKeys = Object.keys(filters);
            queryParams = '?' + filterKeys
                .filter(key => filters[key] !== undefined)
                .map(key => `${key}=${filters[key]}`)
                .join('&');
        }
        return this._http.get<Estados[]>(`${environment.baseURL}${environment.endpoints.userReservations}${queryParams}`);
    }

    getUserActiveReservations(): Observable<any> {
        return this._http.get<any>(`${environment.baseURL}${environment.endpoints.user_active_reservations}`);
    }

    getUserReservationDetails(id:string):Observable<UserReservationDetails>{
        return this._http.get<UserReservationDetails>(`${environment.baseURL}${environment.endpoints.reservation}/${id}`);

    }
}