import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injectable,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _moment from 'moment';
import _rollupMoment from 'moment';
import Holidays from 'date-holidays';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbDatepicker,
  NgbDatepickerI18n,
  NgbDatepickerNavigateEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TranslationWidth } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslationService } from '../../../services/translation.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlojamientosFilters } from '../../../interfaces/alojamientos-filters.interface';
import { FiltersService } from '../../../../core/services/filters.service';
import { StoreFiltersService } from '../../../services/storeFilters.service';
import { Bloqueos, BloqueosCalendar } from '../../../interfaces/bloqueos.interface';
import { dateToNgbDateStruct, ngbDateStructToNgbDate, ngbDateToNgbDateStruct, timestampToNgbDateStruct } from '../../../helpers/helpers';
import { MatDialog } from '@angular/material/dialog';
import { LnDialogComponent } from '../../ln-dialog/ln-dialog.component';
import { LnDialogInfoComponent, ModalInfoData } from '../ln-dialog-info/ln-dialog-info.component';


var hd = new Holidays('GT');

const I18N_VALUES: { [key: string]: { weekdays: string[]; months: string[] } } =
{
  es: {
    weekdays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
    months: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
  },
  en: {
    weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
};

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  private i18nValues!: { weekdays: string[]; months: string[] };
  i18n: any;

  constructor(private _translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.updateI18nValues(this._translateService.currentLang);
    this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateI18nValues(event.lang);
      this.i18n = event.lang;
    });
  }
  updateI18nValues(lang: string) {
    this.i18nValues = I18N_VALUES[lang as 'es' | 'en'];
  }
  getWeekdayLabel(
    weekday: number,
    width?: TranslationWidth | undefined
  ): string {
    return this.i18nValues.weekdays[weekday - 1];
  }
  getWeekdayShortName(weekday: number): string {
    return this.i18nValues.weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return this.i18nValues.months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}


@Component({
  selector: 'app-ln-date-picker',
  templateUrl: './ln-date-picker.component.html',
  styleUrls: ['./ln-date-picker.component.css'],
  providers: [
    {
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18n,
    },
  ],
})
export class LnDatePickerComponent implements OnInit, AfterViewInit {
  @Input() cardBooking: string | undefined;
  @ViewChild('range-end') end: ElementRef | undefined;
  @Output() rangeChange = new EventEmitter<FormGroup>();
  @ViewChild(NgbDatepicker) datepicker!: NgbDatepicker;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @Input() reset: boolean | undefined;
  @Input() fechasBloqueo: Bloqueos[] = [];
  calendar = inject(NgbCalendar);
  hoveredDate: NgbDate | null = null;
  @Input() fromDate: NgbDate | null = null;
  @Input() toDate: NgbDate | null = null;
  @Output() resetChange = new EventEmitter<boolean>();
  currentMonth: number | null = null;
  today = this.calendar.getToday();
  nights: any;
  activeMonth = this.today.month;
  showStart: any;
  showEnd: any;
  holidays: any;
  isCalendarOpen = false;
  currentHolidays: any[] = [];
  model: NgbDateStruct | undefined;
  maxDate: NgbDateStruct = {
    year: this.today.year,
    month: this.today.month + 4,
    day: this.getLastDayOfMonth(this.today.year, this.today.month + 4),
  };
  defaultDateStart: NgbDate = new NgbDate(this.today.year, this.today.month, this.today.day);
  defaultDateEnd: NgbDate | null = null;
  filtersAlojamientoDates: AlojamientosFilters = {};


  disabled = (date: NgbDateStruct) =>
    new NgbDate(date.year, date.month, date.day).before(this.today);
  date: { year: number; month: number } | undefined;
  
  isDisabled = (date: NgbDateStruct) => {
    const dateObj = new Date(date.year, date.month - 1, date.day);
    // Verificar si la fecha está dentro de alguno de los rangos deshabilitados
    const isInRange = this.disabledDates.some(range => {
      const start = new Date(range.start.year, range.start.month - 1, range.start.day);
      const end = new Date(range.end.year, range.end.month - 1, range.end.day);
      return dateObj >= start && dateObj <= end;
    });

    return isInRange;
  };
  
  disabledDates: BloqueosCalendar[] = [];

  constructor(
    private _translateService: TranslateService,
    private _translationService: TranslationService,
    private customDatepickerI18n: CustomDatepickerI18n,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private _filtersService: FiltersService,
    private _storeFiltersService: StoreFiltersService,
    private _modalServ : MatDialog
  ) { }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.trigger.closeMenu();
  }
  getLastDayOfMonth(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }

  convertNgbDateToString(date?: NgbDate | null): string | null {
    return `${date?.year}-${date?.month}-${date?.day}`;
  }

  ngOnInit() {
    //set dias bloqueados para manipular las fechas
    this.defaultDateEnd = this.setNextDay(this.defaultDateStart);
    if(this.fechasBloqueo.length > 0) this.setBloqueos();
    
    //this.navigateWithQueryParams();
    // Trae la copia de los filtros hasta antes de la actualización del mapa
    let storedFilter = this._storeFiltersService.getFilter();
    if (storedFilter.toDate) {
      this.filtersAlojamientoDates.toDate = storedFilter.toDate ?? undefined;
      this.toDate = this.parseStringToDate(storedFilter.toDate) ?? null;
      
    } else {
      const nextDateEndEnabled =  ngbDateStructToNgbDate(this.findNextEnabledDate(this.defaultDateEnd));
      this.toDate = this.fechasBloqueo.length> 0 ? nextDateEndEnabled :this.defaultDateEnd;
      this.setFechasEnFiltro('toDate');
    }
    if (storedFilter.fromDate) {
      this.filtersAlojamientoDates.fromDate = storedFilter.fromDate ?? undefined;
      this.fromDate = this.parseStringToDate(storedFilter.fromDate) ?? null;
    } else {
      const nextDateStartEnabled =  ngbDateStructToNgbDate(this.findNextEnabledDate(this.defaultDateEnd));
      this.fromDate = this.fechasBloqueo.length> 0 ? nextDateStartEnabled : this.defaultDateStart;
      this.setFechasEnFiltro('fromDate');
    }
    
    //si son iguales las fechas se agrega un dia mas al toDate
    if(this.toDate?.day === this.fromDate?.day) {
      const newToDate = this.setNextDay(ngbDateToNgbDateStruct(this.toDate));
      this.toDate = ngbDateStructToNgbDate(newToDate);
      this.setFechasEnFiltro('toDate');
    }

    this.devuelveRangoHabil(this.fromDate, this.toDate, true);
    

    // Si hay nuevos filtros setea los filtros 
    if ((this.hasFilters(this.filtersAlojamientoDates) && (this.filtersAlojamientoDates.fromDate && this.filtersAlojamientoDates.toDate))) {
      const parsedFromDate = this.parseStringToDate(this.filtersAlojamientoDates.fromDate);
      const parsedToDate = this.parseStringToDate(this.filtersAlojamientoDates.toDate);

      if (parsedFromDate && parsedToDate) {

        if (parsedFromDate && parsedToDate) {
          this.fromDate = parsedFromDate;
          this.toDate = parsedToDate;
        }
      }
    }

    this._translateService.onLangChange.subscribe(
      async (event: LangChangeEvent) => {
        this.getButtonText(event.lang);
        this.customDatepickerI18n.updateI18nValues(event.lang);
        // el codigo siguiente fue la unica forma que encontre para que se actualice el idioma del datepicker
        // ya que el metodo updateI18nValues no actualiza el idioma del datepicker
        // por lo que se debe navegar al siguiente mes y luego al mes anterior para que se actualice el idioma
        // esto es un bug del datepicker de ng-bootstrap
        //=======================================================================================================
        this.navigateToNextMonth();
        this.navigateToPreviousMonth();
        //=======================================================================================================
      }
    );
    
    this.nights = this.calculateNights();
  }

  setFechasEnFiltro(tipo: string) {
    switch(tipo){
      case 'fromDate':
        this._storeFiltersService.setFilter({ fromDate: this.convertNgbDateToString(this.fromDate) });
        break;
      case 'toDate':
        this._storeFiltersService.setFilter({toDate: this.convertNgbDateToString(this.toDate) });
        break;
      default:
        this._storeFiltersService.setFilter({ fromDate: this.convertNgbDateToString(this.fromDate) });
        this._storeFiltersService.setFilter({toDate: this.convertNgbDateToString(this.toDate) });
      break;
    }
  }

  ngAfterViewInit(): void {
    this.navigateToSelectedMonth(this.toDate);
    this.cdr.detectChanges();
  }


  isInRange(date: NgbDateStruct) {
    const startDate = new Date(
      this.today.year,
      this.today.month - 1,
      this.today.day + 1
    );
    const endDate = new Date(
      this.today.year,
      this.today.month + 4,
      this.getLastDayOfMonth(this.today.year, this.today.month)
    );
    const dateToCheck = new Date(date.year, date.month - 1, date.day);
    return dateToCheck >= startDate && dateToCheck <= endDate;
  }

  isToday(date: NgbDateStruct) {
    const currentDate = new Date();
    return (
      date.year === currentDate.getFullYear() &&
      date.month === currentDate.getMonth() + 1 &&
      date.day === currentDate.getDate()
    );
  }

  isAboveMax(date: NgbDateStruct) {
    const maxDate = new Date(
      this.maxDate.year,
      this.maxDate.month - 1,
      this.maxDate.day
    );
    const dateToCheck = new Date(date.year, date.month - 1, date.day);
    return dateToCheck < maxDate;
  }

  @Output() dateRangeSelected = new EventEmitter<{
    fromDate: NgbDate | null;
    toDate: NgbDate | null;
  }>();

  getHolidays() {
    let date = new Date();
    hd.setLanguages(this._translateService.currentLang || 'es');
    this.holidays = hd.getHolidays(date.getFullYear());
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this._storeFiltersService.setFilter({ fromDate: this.convertNgbDateToString(this.fromDate) });
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this._storeFiltersService.setFilter({ toDate: this.convertNgbDateToString(this.toDate) });
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.toDate) {
      this.devuelveRangoHabil(this.fromDate, this.toDate);
    }

    const rangoFechasValido = this.validarRango(this.fromDate, this.toDate);
    if(!rangoFechasValido && this.toDate){
      this.devuelveRangoHabil(this.fromDate, this.toDate);
    } else {
      this.nights = this.calculateNights();
      this.actualizarCambioFechas();
    }
    this.dateRangeSelected.emit({
      fromDate: this.fromDate,
      toDate: this.toDate,
    });

  }
  
  onCalendarClosed() {
    if (this.isCalendarOpen) {
      if (this.toDate === null) {
        this.toDate = this.addDaysToNgbDate(this.fromDate, 1);
        this._storeFiltersService.setFilter({ toDate: this.convertNgbDateToString(this.toDate) });
      }
      this.isCalendarOpen = false;
    }
  }

  onCalendarOpened() {
    this.isCalendarOpen = true;
  }

  actualizarCambioFechas() {
    this.updateUrlWithDateRange();
    // Emit the new date range
    this.dateRangeSelected.emit({
      fromDate: this.fromDate,
      toDate: this.toDate,
    });

   
  }

  updateUrlWithDateRange() {
    if (this.fromDate && this.toDate) {
      const queryParams = {
        fromDate: `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`,
        toDate: `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
      };
      
      this._filtersService.setPreviousFilters(queryParams);
      this.setFechasEnFiltro('fromDate');


      /*     this._router.navigate([], {
            queryParams: queryParams,
            queryParamsHandling: 'merge' 
          }); */
    }
  }

  calculateNights(): any {
    const nights =
      this.fromDate && this.toDate ? this.toDate.day - this.fromDate.day : 0;
    return nights;
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  
  isRangeStart(date: NgbDate) {
    return this.fromDate && this.fromDate.equals(date);
  }

  isRangeEnd(date: NgbDate) {
    return this.toDate && this.toDate.equals(date);
  }

  onNavigate(event: NgbDatepickerNavigateEvent) {
    this.currentMonth = event.next ? event.next.month : null;
    this.updateCurrentHolidays();
  }

  updateCurrentHolidays() {
    this.getHolidays();
    this.currentHolidays = []; // Limpiar el array

    for (let i = 0; i < this.holidays.length; i++) {
      let date = new Date(this.holidays[i].date);
      let month = date.getMonth() + 1; // +1 para obtener un valor de 1 a 12

      if (month === this.currentMonth) {
        this.currentHolidays.push(this.holidays[i]); // Añadir el festivo al array
      }
    }
  }

  getButtonText(lang: string): string {
    let text = '';
    if (lang === 'es') {
      if (this.fromDate) {
        let monthLabel = this.customDatepickerI18n.getMonthFullName(
          this.fromDate.month
        );
        text += this.fromDate.day + '/' + monthLabel + ' - ';
      }
      if (this.toDate && this.toDate.day && this.toDate.month) {
        let monthLabel = this.customDatepickerI18n.getMonthFullName(
          this.toDate.month
        );
        text += this.toDate.day + '/' + monthLabel;
      }
    } else if (lang === 'en') {
      if (this.fromDate) {
        let monthLabel = this.customDatepickerI18n.getMonthFullName(
          this.fromDate.month
        );
        text += monthLabel + '/' + this.fromDate.day + ' - ';
      }
      if (this.toDate && this.toDate.day && this.toDate.month) {
        let monthLabel = this.customDatepickerI18n.getMonthFullName(
          this.toDate.month
        );
        text += monthLabel + '/' + this.toDate.day;
      }
    }

    return text ? text : this._translateService.instant('searchBar.textChoose');
  }

  getButtonTextForCurrentLang(): string {
    const lang = this._translationService.getCurrentLang;
    return this.getButtonText(lang);
  }

  // Verificar si hay filtros en el objeto
  private hasFilters(filters: AlojamientosFilters): boolean {
    return Object.values(filters).some(value => value !== undefined);
  }

  parseStringToDate(dateString: string): NgbDate | null | undefined {

    if (dateString) {
      const parts = dateString.split('-');
      if (parts.length === 3) {
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const day = parseInt(parts[2], 10);
        if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
          return new NgbDate(year, month, day);
        }
      }
    }

    return undefined;
  }

  //=======================================================================================================
  async navigateToNextMonth() {
    //navegar al siguiente mes
    const today = this.datepicker.model
      ? this.datepicker.model.firstDate
      : null;
    if (today) {
      const nextMonth = new NgbDate(today.year, today.month + 1, 1);
      await this.datepicker.navigateTo(nextMonth);
    } else {
      console.error('No se pudo obtener la fecha actual del NgbDatepicker.');
    }
  }

  async navigateToPreviousMonth() {
    //navegar al mes anterior
    const today = this.datepicker.model
      ? this.datepicker.model.firstDate
      : null;

    if (today) {
      const prevMonth = new NgbDate(today.year, today.month - 1, 1);
      await this.datepicker.navigateTo(prevMonth);
    } else {
      console.error('No se pudo obtener la fecha actual del NgbDatepicker.');
    }
  }
  //=======================================================================================================
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['reset'] && changes['reset'].currentValue === true) {
      this.resetDate();
    }
  }

  resetDate() {
    const today = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
    const tomorrow = NgbDate.from({ year: today.year, month: today.month, day: today.day + 1 });

    this.fromDate = today;
    this.toDate = tomorrow;
    this._storeFiltersService.setFilter({ fromDate: this.convertNgbDateToString(this.fromDate) });
    this._storeFiltersService.setFilter({ toDate: this.convertNgbDateToString(this.toDate) });
    this.nights = 0;
    this.dateRangeSelected.emit({
      fromDate: this.fromDate,
      toDate: this.toDate,
    });
    this.reset = false;
    this.resetChange.emit(this.reset);
  }

  setBloqueos() {
    const bloqueos = this.fechasBloqueo?.map( (fecha) => {
      return {
        start: timestampToNgbDateStruct(fecha.fechaDesde),
        end: timestampToNgbDateStruct(fecha.fechaHasta)
      }
    });
    this.disabledDates = bloqueos;
  }

  findNextEnabledDate(date: NgbDateStruct | null): NgbDateStruct | null {
    if(!date) {
      return null;
    }
    let nextDate = new Date(date.year, date.month - 1, date.day);
  
    while (this.isDisabled(date)) {
      nextDate.setDate(nextDate.getDate() + 1);
    }
    
    return dateToNgbDateStruct(nextDate);
  }

  setNextDay(date: NgbDateStruct | null) {
    if (date === null) {
      return null;
    }
    let nextDate = ngbDateStructToNgbDate(date);
    return this.addDaysToNgbDate(nextDate, 1);
  }

  navigateToSelectedMonth(date: NgbDateStruct | null) {
    if (date) {
      this.datepicker.navigateTo({ year: date.year, month: date.month });
    }
  }

  findNextEnabledRange(fromDate: NgbDateStruct, rangeLength: number): { fromDate: NgbDateStruct, toDate: NgbDateStruct } {
    let nextFromDate = ngbDateStructToNgbDate(fromDate);

    while (true) {
      const proposedToDate = this.addDaysToNgbDate(nextFromDate,rangeLength -1 )

      const fromStruct = ngbDateToNgbDateStruct(nextFromDate);
      const toStruct = ngbDateToNgbDateStruct(proposedToDate);

      if (this.validarRango(fromStruct, toStruct)) {
        return { fromDate: fromStruct!, toDate: toStruct! };
      }

      nextFromDate = this.addDaysToNgbDate(nextFromDate, 1)
    }
  }

  validarRango(fromDate: NgbDateStruct | null, toDate: NgbDateStruct | null): boolean {
    if (!fromDate || !toDate) {
      return false;
    }
    
    let currentDate = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    const endDate = new Date(toDate.year, toDate.month - 1, toDate.day);

    while (currentDate <= endDate) {
      const currentNgbDateStruct = dateToNgbDateStruct(currentDate);
      if (this.isDisabled(currentNgbDateStruct)) {
        return false; // Rango inválido si se encuentra un día deshabilitado
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return true; // Rango válido si no se encuentran días deshabilitados
  }

  devuelveRangoHabil(fromDate: NgbDateStruct | null, toDate: NgbDateStruct | null, esInicial = false) {
    if (!fromDate || !toDate) {
      return;
    }
    if (!this.validarRango(fromDate, toDate)) {
      if (fromDate) {
        const nextValidRange = this.findNextEnabledRange(fromDate, 2);// se coloca 2 porque por defecto es 1 noche minimo
        this.fromDate = ngbDateStructToNgbDate(nextValidRange.fromDate);
        this.toDate = ngbDateStructToNgbDate(nextValidRange.toDate);
        this.setFechasEnFiltro('ambas');
        if(!esInicial){
          this.openModalInfo();
          this.nights = this.calculateNights();
          this.actualizarCambioFechas();
        }
      }
    }
  }

  addDaysToNgbDate(date: NgbDate | null, days: number): NgbDate | null {
    if(!date){
      return null;
    }
    const jsDate = new Date(date.year, date.month - 1, date.day);
    jsDate.setDate(jsDate.getDate() + days);
    return new NgbDate(jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate());
  }

  openModalInfo(){
    const lang = this._translationService.getCurrentLang;
    const title=lang==='es'?'Información importante':'Important information';
    const textHeader=lang==='es'?'El intervalo de fechas seleccionado no se encuentra disponible.':'The selected date range is not available';
    const textBody=lang==='es'?'Por favor elije otra fecha':'Please pick a different date';
    const buttonText=lang==='es'?'Cerrar':'Close';

    const data: ModalInfoData = {
      title: title,
      textHeader: textHeader,
      textBody:textBody,
      buttonText: buttonText,
      buttonTextCancel: 'Cancelar',
      showButtonCancel: false
    };
    this._modalServ.open(LnDialogInfoComponent, {
      data
    })
  }
}
