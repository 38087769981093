import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ReservationRespository } from '../../../data/repository/reservation-repository';
import { TranslationService } from '../../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { RESERVATION_STATUS } from '../../../core/constants/reservations-status-contants';
import { mesesEng, mesesEsp } from '../../../core/constants/months';


@Component({
  selector: 'app-card-reservation-state',
  templateUrl: './card-reservation-state.component.html',
  styleUrl: './card-reservation-state.component.css'
})
export class CardReservationStateComponent implements OnInit {

  constructor(private elementRef: ElementRef,
    private _reservations: ReservationRespository,
    private _translate: TranslationService,
    private translateService: TranslateService,

  ) {

  }

  @Input() location: string = '';
  @Input() fromDate: string = '29 dic 2023';
  @Input() toDate: string = '03 ene 2024';
  @Input() state: string = '';
  @Input() idReserva: string = '';
  currentLang:string='';
  startDate:string='';
  endDate:string='';
  public RESERVATION_STATUS = RESERVATION_STATUS;


  ngOnInit(): void {

    this._translate.onLangChange.subscribe(() => {
      this.dateRanges(this.fromDate, this.toDate);
    });


    if (this.state.length > 0 && this.state == RESERVATION_STATUS.DONE) {
      this.adjustColor(false);
    }
    if (this.state.length > 0 && this.state == RESERVATION_STATUS.CANCELLED) {
      this.adjustColor(true);
    }
    try {
      this.dateRanges(this.fromDate,this.toDate);
    }
    catch (e) { console.log('error en fecha ') }
  }



  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit', // El día debe ser '2-digit' o 'numeric'
      month: 'short', // El mes debe ser 'short' para abreviatura
      year: 'numeric', // El año debe ser 'numeric'
    };

    // Formatear la fecha
    const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);

    return formattedDate;
  }

  dateRanges(rangoInicio: string, rangoFin: string) {
    this._translate.getTranslation(this._translate.getCurrentLang).subscribe(() => {
      this.currentLang = this._translate.getCurrentLang;
    });
    const [inicioAño, inicioMes, inicioDía] = rangoInicio.split('T')[0].split('-').map(Number);
    const [finAño, finMes, finDía] = rangoFin.split('T')[0].split('-').map(Number);
  
    let meses:string[]=[];

    if(this.currentLang=='es'){
      meses=mesesEsp
    }
    else{
      meses=mesesEng
    }

    const fechaInicio = new Date(inicioAño, inicioMes - 1, inicioDía);  
    const fechaFin = new Date(finAño, finMes - 1, finDía);

    const formatoFechaInicio = `${fechaInicio.getDate()} ${meses[fechaInicio.getMonth()]} ${fechaInicio.getFullYear()}`;
    const formatoFechaFin = `${fechaFin.getDate()} ${meses[fechaFin.getMonth()]} ${fechaFin.getFullYear()}`;

    this.startDate = formatoFechaInicio;
    this.endDate = formatoFechaFin;
  }


  adjustColor(cancelado: boolean): void {
    const cardContainer = this.elementRef.nativeElement.querySelector('.stateContainer') as HTMLElement;
    if (cardContainer && !cancelado) {
      cardContainer.style.backgroundColor = `rgba(207,216,214,1)`;
    }
    if (cardContainer && cancelado) {
      cardContainer.style.backgroundColor = `rgba(224,215,210,1)`;
    }
  }

}
